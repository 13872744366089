.App {
  text-align: center;
  background: url('../images/earth-nasa.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: #e1f5fe;
  margin: 10vh 0; 
}

.App-link {
  color: #e1f5fe;
}

svg {
  padding: 0 8px 4px 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.last-url {
  color: whitesmoke;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.last-url span {
  background-color: rgba(0,0,0,0.4);
  padding: 0 8px;
}

.converter {
  margin: 32px 0;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #001219;
  color: white;
  text-align: center;
  font-size: 12px;
  min-height: 48px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

.footer span {
  padding-left: 2em;
}

a {
  color: #edf6f9;
  margin: 8px;
}

.featureTitle {
  padding-top: 80px;
  margin-top: 120px;

}

.feature {
  margin-bottom: 120px;
  padding-top: 80px;
  background: url('../images/earth-nasa.jpeg');
}